import CloseIcon from '@mui/icons-material/Close';
import { FormControl, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, Slider, Stack, Switch, TextField as DefaultTextField } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ButtonComponent from '../../common/button';
import { Wrapper } from '../../common/components';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1292px',
    height: '671px',
    bgcolor: '#fff',
    border: 'none',
    p: 2,
    borderRadius: '10px',
};

const ModalHeader = styled.div`
    text-align: right;
`;

const TextField = styled(DefaultTextField)`
    background: #fff;
`;

const StyledLabel = styled(FormControlLabel)`
    && {
        width: 100%;
        margin-left: 0;
        align-items: center;
        justify-content: space-between;
    }
    .MuiFormControlLabel-label {
        width: 100%;
        max-width: 339px;
        text-align: left;
        font-size: 14px;
        font-family: 'Inter';
    }
`;

const ConfigWrapper = styled.div`
    max-height: 600px;
    overflow: scroll;
    overflow-x: hidden;
`;


function valuetext(value) {
    return `${value}x`;
}

const OPTIMAL = 'optimal';
const HARD = 'hard';

const ConfigurationsView = ({ open, handleClose }) => {

    const { additional: { depot, vehicle, post_optimization }, main } = useSelector(state => state.admin.scenario.params);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <ModalHeader>
                    <ButtonComponent variant="text">
                        <CloseIcon color="000" onClick={handleClose} />
                    </ButtonComponent>
                </ModalHeader>
                <ConfigWrapper>
                    <Typography id="modal-modal-description" sx={{ mb: 2, fontSize: '20px', fontWeight: 500 }}>
                        Основные параметры
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item spacing={2} xs={6}>
                            <Stack spacing={2}>
                                <Wrapper className="grey">
                                    <Stack spacing={2}>
                                        <FormGroup>
                                            <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '98px' }}>
                                                <TextField disabled size="small" min value={main.delivery_deadline_hours} inputProps={{ inputMode: 'numeric', pattern: '[4-24]', min: 4, max: 24 }} />
                                            </FormControl>} label="Требуемое время доставки биоматериала в Лабораторию, часов" />
                                        </FormGroup>
                                        <FormGroup>
                                            <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '98px' }}>
                                                <TextField disabled size="small" value={main.delivery_late_time} /></FormControl>} label="Допустимое время опоздания, часов" />
                                        </FormGroup>
                                        <FormGroup>
                                            <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '98px' }}>
                                                <TextField disabled size="small" value={main.processing_time} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} /></FormControl>} label="Требуемое время для обработки анализа, минут" />
                                        </FormGroup>
                                    </Stack>
                                </Wrapper>
                            </Stack>
                        </Grid>
                        <Grid item spacing={2} xs={6}>
                            <Stack spacing={2}>
                                <Wrapper className="grey">
                                    <Stack spacing={2}>
                                        <FormGroup>
                                            <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '98px' }}>
                                                <TextField disabled size="small" value={main.pickup_min_time} /></FormControl>} label="Минимальное возможное время забора биоматериала из ЛПУ, время" />
                                        </FormGroup>
                                        <FormGroup>
                                            <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '98px' }}>
                                                <TextField disabled size="small" value={main.pickup_early_time} /></FormControl>} label="Допустимое время более раннего прибытия, часов" />
                                        </FormGroup>
                                        <FormGroup>
                                            <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '98px' }}>
                                                <TextField disabled size="small" value={main.service_duration} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} /></FormControl>} label="Продолжительность обслуживания при заезде в ЛПУ, минут" />
                                        </FormGroup>
                                        <FormGroup>
                                            <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '198px' }}>
                                                <TextField disabled size="small" value={main.date} /></FormControl>} label="Дата" />
                                        </FormGroup>
                                    </Stack>
                                </Wrapper>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Typography id="modal-modal-description" sx={{ mb: 2, fontSize: '20px', fontWeight: 500 }}>
                        Дополнительные параметры
                    </Typography>
                    <Grid container spacing={2} >
                        <Grid item xs={6}>
                            <Typography>Лаборатории</Typography>
                            <Stack spacing={2}>
                                <Wrapper className="grey">
                                    <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '98px' }}>
                                        <TextField disabled size="small" value={depot.main_throughput_units_per_hour} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                                    </FormControl>} label="Производительность ЦКДЛ, пробирки/час" />
                                </Wrapper>
                                <Wrapper className="grey">
                                    <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '98px' }}>
                                        <TextField disabled size="small" value={depot.satellite_throughput_units_per_hour} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} /></FormControl>} label="Производительность Сателлитной лаборатории, пробирки/час" />
                                </Wrapper>
                                <Wrapper className="grey">
                                    <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '198px' }}>
                                        <Stack direction="row" alignItems="center">
                                            <Typography fontSize="12px">умеренно</Typography>
                                            <Switch disabled size="small" checked={vehicle.optimize_regular_delivery} inputProps={{ 'aria-label': 'ant design' }} />
                                            <Typography fontSize="12px">сильно</Typography>
                                        </Stack>
                                    </FormControl>} label="Оптимизация равномерности прибытия автомобилей в лабораторию" />
                                </Wrapper>
                                <Wrapper className="grey">
                                    <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '528px' }}>
                                        <Stack spacing={1} direction="row" alignItems={'center'}>
                                            <div>с</div>
                                            <TextField disabled size="small" value={depot.time_window.split('-')[0]} type="time" />
                                            <div>по</div>
                                            <TextField disabled size="small" value={depot.time_window.split('-')[1]} type="time" />
                                        </Stack>
                                    </FormControl>} label="Время работы лаборатории" />
                                </Wrapper>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>Автомобиль</Typography>
                            <Stack spacing={2}>
                                <Wrapper className="grey">
                                    <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '98px' }}>
                                        <TextField disabled size="small" value={vehicle.max_vehicle_count} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} /></FormControl>} label="Максимальное число автомобилей" />
                                </Wrapper>
                                <Wrapper className="grey">
                                    <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '98px' }}>
                                        <TextField disabled size="small" value={vehicle.capacity_units} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} /></FormControl>} label="Вместительность автомобиля, количество пробирок" />
                                </Wrapper>
                                <Wrapper className="grey">
                                    <StyledLabel minWidth="100%" labelPlacement="start" control={<FormControl sx={{ width: '198px' }}>
                                        <Stack direction="row" alignItems="center">
                                            <Typography fontSize="12px">умеренно</Typography>
                                            <Switch disabled size="small" checked={vehicle.optimize_vehicle_count !== OPTIMAL} inputProps={{ 'aria-label': 'ant design' }} />
                                            <Typography fontSize="12px">сильно</Typography>
                                        </Stack>
                                    </FormControl>} label="Оптимизация кол-ва автомобилей" />
                                </Wrapper>
                            </Stack>
                            <Typography mt={2}>
                                Программные Настройки
                            </Typography>
                            <Stack>
                                <Wrapper className="grey">
                                    <Typography>Степень оптимизации маршрутов</Typography>
                                    <Stack direction="row" justifyContent={"space-between"} alignItems="flex-start">
                                        <RadioGroup
                                            aria-label="gender"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                            value={post_optimization}
                                            disabled
                                        >
                                            <Stack>
                                                <FormControlLabel value='low' control={<Radio />} label="Низкая(30 сек)" />
                                                <FormControlLabel value='normal' control={<Radio />} label="Средняя(6мин)" />
                                                <FormControlLabel value='high' control={<Radio />} label="Высокая(30 мин)" />
                                            </Stack>
                                        </RadioGroup>
                                    </Stack>
                                </Wrapper>
                            </Stack>
                        </Grid>
                    </Grid>
                </ConfigWrapper>
            </Box>
        </Modal>
    )
}

export default ConfigurationsView;

import { CircularProgress } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Map, Placemark, Polyline, YMaps, ZoomControl } from 'react-yandex-maps';
import styled from 'styled-components';
import color from '../colors-pallete';
import Point from '../points';
import { setPoint } from './redux/actions';

const MapWrapper = styled.div`
    border: 0.5px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
    &.can-select {
        border: 0.5px solid #249580;
    }
`;

const apikey = '47ef0390-da12-4513-b2f7-12ef96644b49';

const MapRoutesComponent = ({ routes, byRoads, activeRoute, droppedLocations, colorsMap }) => {
    const map = useRef(null); //instanceRef
    const mapRef = useRef(null); // ymaps
    const routesRef = useRef({});
    const [selectedRoute, setSelectedRoute] = useState(null);
    const { scenario: { region_id, depots }, regions } = useSelector(state => state.admin);
    const selectedRegion = regions.find(i => i.id === region_id);
    // const points = routes.map(i => i.result.routes.route)

    const dispatch = useDispatch();
    const mapGlobalState = useSelector(state => state.map);

    useEffect(() => {
        if (mapRef.current && map.current) {
            if (byRoads) {
                addRoute();
                activeRoute && activeRoute !== 'reset' && setActiveRoute(activeRoute);
            } else {
                destroyRoutes();
            }
        }
    }, [byRoads])

    useEffect(() => {
        if(activeRoute === 'reset') {
            setActiveRoute(null);
            setSelectedRoute(null);
        }
        if (activeRoute && activeRoute !=='reset') {
            setActiveRoute(activeRoute);
            setSelectedRoute(activeRoute);
        }
    }, [activeRoute])

    const addRoute = (ymaps) => {
        const boundPoints = routes.reduce((acc, curr) => {
            const routePoints = curr.route.map(({ node: { value: { point } } }) => [point.lat, point.lon]);
            return [...acc, ...routePoints];
        }, [])
        if (map && map.current && ymaps) {
            map.current.setBounds(ymaps.util.bounds.fromPoints(boundPoints), {
                zoomMargin: 10,
                callback: (err) => console.log(err),
            });

            const COLORS = ["#F0F075", "#FB6C3F", "#3D4C76", "#49C0B5"];
            const objectManager = new ymaps.ObjectManager();
            ymaps.borders
                .load("001", {
                    lang: "ru",
                    quality: 2
                })
                .then((result) => {
                    const selectedRegions = 'Россия';
                    const regions = result.features.reduce(function (acc, feature) {
                        if (selectedRegions.includes(feature.properties.name)) {
                            return acc;
                        }
                        const iso = feature.properties.iso3166;
                        feature.id = iso;
                        feature.options = {
                            fillOpacity: 0.6,
                            strokeColor: "#FFF",
                            strokeOpacity: 0.5,
                            fillColor: COLORS[2],
                        };
                        acc[iso] = feature;
                        return acc;
                    }, {});
                    result.features = [];
                    for (const reg in regions) {
                        result.features.push(regions[reg]);
                    }
                    objectManager.add(result);
                    map.current.geoObjects.add(objectManager);
                    return ymaps.borders.load("RU", {
                        lang: "ru",
                    })
                })
                .then(function (result) {
                    const selectedRegions = selectedRegion.name.split(',').map(i => i.trim());
                    const regions = result.features.reduce(function (acc, feature) {
                        if (selectedRegions.includes(feature.properties.name)) {
                            return acc;
                        }
                        const iso = feature.properties.iso3166;
                        feature.id = iso;
                        feature.options = {
                            fillOpacity: 0.6,
                            strokeColor: "#FFF",
                            strokeOpacity: 0.5,
                            fillColor: COLORS[3],
                        };
                        acc[iso] = feature;
                        return acc;
                    }, {});
                    result.features = [];
                    for (const reg in regions) {
                        result.features.push(regions[reg]);
                    }
                    objectManager.add(result);
                    map.current.geoObjects.add(objectManager);
                });
        }

        routes.forEach((route, index) => {
            const points = route.route.map(({ node: { value: { point } } }) => [point.lat, point.lon])
            const { vehicle_id, run_number } = route;
            const multiRoute = new mapRef.current.multiRouter.MultiRoute(
                {
                    referencePoints: [...points],
                    params: {
                    }
                },
                {
                    wayPointVisible: false,
                    wayPointStartVisible: false,
                    wayPointFinishVisible: false,
                    wayPointStartIconColor: colorsMap[vehicle_id],
                    wayPointStartIconFillColor: colorsMap[vehicle_id],
                    editorDrawOver: false,
                    wayPointDraggable: false,

                    // Внешний вид транзитных точек.
                    viaPointIconRadius: 20,
                    viaPointIconFillColor: "#000088",
                    viaPointActiveIconFillColor: "#E63E92",
                    // Транзитные точки можно перетаскивать, при этом
                    // маршрут будет перестраиваться.
                    viaPointDraggable: true,
                    // Позволяет скрыть иконки транзитных точек маршрута.
                    viaPointVisible:false,  // @todo

                    // Внешний вид точечных маркеров под путевыми точками.
                    pinIconFillColor: "#000088",
                    pinActiveIconFillColor: "#B3B3B3",
                    routeActiveStrokeColor: colorsMap[vehicle_id],
                    routeActiveStrokeOpacity: 1,
                    routeActiveStrokeWidth: 4,
                    pinIconFillColor: color[10],
                    boundsAutoApply: false,
                    zoomMargin: 30,
                }
            );
            routesRef.current = { ...routesRef.current, [`${vehicle_id}_${run_number}`]: multiRoute };

            map.current.geoObjects.add(multiRoute);

        })
    };

    const setActiveRoute = (route) => {
        Object.entries(routesRef.current).forEach(([key, value], index) => {
            if(route) {
                value.options.set(
                    {
                        routeActiveStrokeColor: route.id !== key ? '#000000' : colorsMap[route.row.color],
                        routeActiveStrokeOpacity: route.id === key ? 1 : 0.1,
                        routeActiveStrokeWidth: route.id === key ? 6 : 4,
                    }
                );
            } else {
                value.options.set(
                    {
                        routeActiveStrokeColor: colorsMap[key.split('_')[0]],
                        routeActiveStrokeOpacity: 1,
                        routeActiveStrokeWidth: 4,
                    }
                );
            }
        });
    }

    const destroyRoutes = () => {
        Object.values(routesRef.current).forEach(i => {
            map.current.geoObjects.remove(i);
        })
        routesRef.current = {};
    }

    const onMapClick = (e) => {
        mapGlobalState.canSelectPoint && dispatch(setPoint(e.get("coords")));
    };

    return routes.length && regions.length && region_id ? (
        <MapWrapper className={mapGlobalState.canSelectPoint && 'can-select'}>
            <YMaps query={{ apikey, load: "package.full" }}>
                <Map
                    modules={["multiRouter.MultiRoute"]}
                    defaultState={{ center: [55.75, 37.57], zoom: 9 }}
                    instanceRef={map}
                    onClick={onMapClick}
                    onLoad={(ymaps) => {
                        mapRef.current = ymaps;
                        addRoute(ymaps);
                    }}
                    width='100%'
                    height={613}
                >
                    {routes.map((route, index) => {
                        return (<Point route={route} key={`${route.vehicle_id}_${index}`}
                            hasSelected={selectedRoute}
                            selected={selectedRoute && `${route.vehicle_id}_${route.run_number}` === selectedRoute.id}
                            color={colorsMap[route.vehicle_id]} />)

                    }
                    )}
                    {!byRoads && routes.map((route, index) => {

                        const hasSelected = selectedRoute;
                        const selected = selectedRoute && `${route.vehicle_id}_${route.run_number}` === selectedRoute.id;
                        const lineColor = colorsMap[route.vehicle_id];
                        return (
                            <Polyline
                                geometry={route.route.map(({ node: { value: { point } } }) => [point.lat, point.lon])}
                                options={{
                                    balloonCloseButton: false,
                                    strokeColor: hasSelected && selected ? lineColor : hasSelected && !selected ? '#000' : lineColor,
                                    strokeWidth: selected ? 8 : 4,
                                    strokeOpacity: hasSelected && selected ? 0.8 : hasSelected && !selected ? 0.2 : 0.8,
                                    // strokeColor: color[index],
                                    // strokeWidth: 4,
                                    // strokeOpacity: 0.8,
                                }}
                            />
                        );
                    })
                    }
                    {droppedLocations.map((route, index) =>
                        <Placemark
                            key={route.id}
                            geometry={[route.point.lat, route.point.lon]}
                            modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                            options={{
                                draggable: false,
                                fillColor: '#000000',
                                strokeColor: '#000000',
                                strokeOpacity: 1,
                                strokeWidth: 30,
                                preset: 'islands#circleIcon',
                                iconColor: '#000000',
                            }}
                            properties={{
                                hintContent: `<b>${route.title}</b> <br/> объект не доступен`,
                                iconContent: '-',
                            }}
                        />
                    )}
                    {depots.map((item, index) =>
                        <Placemark
                            key={item.id}
                            geometry={[item.lat, item.lon]}
                            modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                            options={{
                                draggable: false,
                                fillColor: !item.is_satellite ? '#FB6C3F' : '#249580',
                                strokeColor: !item.is_satellite ? '#FB6C3F' : '#249580',
                                strokeOpacity: 1,
                                strokeWidth: 30,
                                preset: 'islands#blueScienceIcon',
                                iconColor: !item.is_satellite ? '#FB6C3F' : '#249580',
                            }}
                            properties={{
                                hintContent: item.name,
                                // balloonContent: index + 1,
                                iconContent: 'Л',
                            }}
                        />
                    )}
                    <ZoomControl options={{ float: 'left' }} />
                </Map>
            </YMaps>
        </MapWrapper>
    ) : (
        <CircularProgress />
    );
};

export default MapRoutesComponent;
